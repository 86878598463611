<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-card title="동종업계 사고사례 기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="save" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-6">
            <c-text
              label="사고명"
              :required="true"
              name="col1"
              v-model="sameAccident.col1"
            />
          </div>
          <div class="col-6">
            <c-text
              label="사고업체"
              :required="true"
              name="col2"
              v-model="sameAccident.col2"
            />
          </div>
          <div class="col-6">
            <c-text
              label="장소"
              :required="true"
              name="col3"
              v-model="sameAccident.col3"
            />
          </div>
          <div class="col-6">
            <c-datepicker
              label="사고일자"
              name="col4"
              v-model="sameAccident.col4"
            />
          </div>
          <div class="col-12">
            <c-textarea
              label="피해현황"
              :editable="editable"
              name="col5"
              v-model="sameAccident.col5" />
          </div>
          <div class="col-12">
            <c-textarea
              label="원인"
              :editable="editable"
              name="col6"
              v-model="sameAccident.col6" />
          </div>
          <div class="col-12">
            <c-textarea
              label="내용"
              :editable="editable"
              name="col7"
              v-model="sameAccident.col7" />
          </div>
          <!-- <div class="col-3">
            <c-plant
              :required="true"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="sameAccident.plantCd" />
          </div> -->
          
        </template>
      </c-card>
    </div>
    <div class="col-12">
        <c-upload
          :editable="editable"
          label="동종업계 사고사례 사진첨부">
        </c-upload>
    </div>
  </div>
</template>
<script>
export default {
  name: 'same-accidents-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      sameAccident: {
        plantCd: '1',
        col1: '미끄러짐',
        col2: '업체1',
        col3: '장소1',
        col4: '2021-09-18',
        col5: '피해현황피해현황피해현황피해현황피해현황피해현황피해현황',
        col6: '원인원인원인원인원인원인원인원인원인원인원인원인원인원인',
        col7: '내용내용내용내용내용내용내용내용내용내용내용내용내용내용',
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.getList();
    },
    getList() {
    },
    reset() {
      Object.assign(this.$data.sameAccident, this.$options.data().sameAccident);
    },
    addItem() {
      this.grid.data.splice(0, 0, {
        inspectionSeq: 0,
        col1: '',
        col2: '',
        fileNm: '',
      })
    },
    removeItem() {
      let selectData = this.$refs['historyId'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / sameAccident / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, item)
        })
      }
    },
    save() {

    },
    rev() {

    }
  }
};
</script>