var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "동종업계 사고사례 기본정보" },
          },
          [
            _c(
              "template",
              { slot: "card-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "LBLSAVE", icon: "save" },
                          on: { btnClicked: _vm.save },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("c-text", {
                    attrs: { label: "사고명", required: true, name: "col1" },
                    model: {
                      value: _vm.sameAccident.col1,
                      callback: function ($$v) {
                        _vm.$set(_vm.sameAccident, "col1", $$v)
                      },
                      expression: "sameAccident.col1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("c-text", {
                    attrs: { label: "사고업체", required: true, name: "col2" },
                    model: {
                      value: _vm.sameAccident.col2,
                      callback: function ($$v) {
                        _vm.$set(_vm.sameAccident, "col2", $$v)
                      },
                      expression: "sameAccident.col2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("c-text", {
                    attrs: { label: "장소", required: true, name: "col3" },
                    model: {
                      value: _vm.sameAccident.col3,
                      callback: function ($$v) {
                        _vm.$set(_vm.sameAccident, "col3", $$v)
                      },
                      expression: "sameAccident.col3",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("c-datepicker", {
                    attrs: { label: "사고일자", name: "col4" },
                    model: {
                      value: _vm.sameAccident.col4,
                      callback: function ($$v) {
                        _vm.$set(_vm.sameAccident, "col4", $$v)
                      },
                      expression: "sameAccident.col4",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("c-textarea", {
                    attrs: {
                      label: "피해현황",
                      editable: _vm.editable,
                      name: "col5",
                    },
                    model: {
                      value: _vm.sameAccident.col5,
                      callback: function ($$v) {
                        _vm.$set(_vm.sameAccident, "col5", $$v)
                      },
                      expression: "sameAccident.col5",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("c-textarea", {
                    attrs: {
                      label: "원인",
                      editable: _vm.editable,
                      name: "col6",
                    },
                    model: {
                      value: _vm.sameAccident.col6,
                      callback: function ($$v) {
                        _vm.$set(_vm.sameAccident, "col6", $$v)
                      },
                      expression: "sameAccident.col6",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("c-textarea", {
                    attrs: {
                      label: "내용",
                      editable: _vm.editable,
                      name: "col7",
                    },
                    model: {
                      value: _vm.sameAccident.col7,
                      callback: function ($$v) {
                        _vm.$set(_vm.sameAccident, "col7", $$v)
                      },
                      expression: "sameAccident.col7",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("c-upload", {
          attrs: {
            editable: _vm.editable,
            label: "동종업계 사고사례 사진첨부",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }